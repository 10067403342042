import { Params } from "./Params";
import { Path } from "./Path";

/**
 * Page represents a specific page in the app, e.g. a RR <Route path="" />.
 * However, one page may generate multiple Route instances as Paths are translatable.
 */
export enum Page {
  home = "home",
  cockpit = "cockpit",
  search = "search",
  vehicleDetail = "vehicleDetail",
  vehicleComparison = "vehicleComparison",
  favorites = "favorites",
  savedSearches = "savedSearches",
  dealerLocator = "dealerLocator",
  dealerDetail = "dealerDetail",
  brandList = "brandList",
  brandDetail = "brandDetail",
  brandModels = "brandModels",
  brandPromotions = "brandPromotions",
  brandVehicles = "brandVehicles",
  modelDetail = "modelDetail",
  magazineOverview = "magazineOverview",
  magazineArticle = "magazineArticle",
  promotionOverview = "promotionOverview",
  promotion = "promotion",
  postLogin = "postLogin",
  savedSearchMail = "savedSearchMail",
  staticGeneral = "staticGeneral",
  staticYourVehicle = "staticYourVehicle",
  staticBrand = "staticBrand",
  staticModel = "staticModel",
  staticPreview = "staticPreview",
  ffuTestDrive = "ffuTestDrive",
  testDriveBrand = "testDriveBrand",
  testDriveModel = "testDriveModel",
  leasingIntro = "leasingIntro",
  leasingApplication = "leasingApplication",
  leasingRequest = "leasingRequest",
  vehiclePurchase = "vehiclePurchase",
  vehiclePurchaseConclude = "vehiclePurchaseConclude",
  ffuGeneralInquiry = "ffuGeneralInquiry",
  newVehicleInquiry = "newVehicleInquiry",
  vehicleEvaluationIntro = "vehicleEvaluationIntro",
  vehicleEstimation = "vehicleEstimation",
  ffuVehicleEvaluation = "ffuVehicleEvaluation",
  checklist = "checklist",
  competitionForm = "competitionForm",
  newsletterSubscribe = "newsletterSubscribe",
  matchmaker = "matchmaker",
  brandMarketplace = "brandMarketplace",
  modelMarketplace = "modelMarketplace",
}

export type PageConfiguration = {
  route: (Path | Params)[];
  // indicates whether the page is suitable as a "post-login" redirect, default: false
  // which means that the page is a redirect target after login
  preventAsPostLoginUrl?: boolean;
  wildcard?: boolean;
};

export const PAGE_DEFINITION: Record<Page, PageConfiguration> = {
  [Page.home]: {
    route: [Params.lang],
  },
  [Page.cockpit]: {
    route: [Params.lang, Path.my, Path.cockpit],
  },
  [Page.search]: {
    route: [Params.lang, Path.search],
  },
  [Page.vehicleDetail]: {
    route: [Params.lang, Path.vehicle, Params.slug_vehicleId],
  },
  [Page.vehicleComparison]: {
    route: [Params.lang, Path.vehicleComparison],
  },
  [Page.favorites]: {
    route: [Params.lang, Path.my, Path.favorites],
  },
  [Page.savedSearches]: {
    route: [Params.lang, Path.my, Path.savedSearches],
  },
  [Page.dealerLocator]: {
    route: [Params.lang, Path.dealerLocator],
  },
  [Page.dealerDetail]: {
    route: [Params.lang, Path.dealer, Params.slug_dealerId],
  },
  [Page.brandList]: {
    route: [Params.lang, Path.brands],
  },
  [Page.brandDetail]: {
    route: [Params.lang, Path.brand, Params.slug_brand],
  },
  [Page.brandModels]: {
    route: [Params.lang, Path.brand, Params.slug_brand, Path.models],
  },
  [Page.brandMarketplace]: {
    route: [Params.lang, Path.brand, Params.slug_brand, Path.marketplace],
  },
  [Page.brandPromotions]: {
    route: [Params.lang, Path.brand, Params.slug_brand, Path.promotions],
  },
  [Page.brandVehicles]: {
    route: [Params.lang, Path.brand, Params.slug_brand, Path.vehicles],
  },
  [Page.modelDetail]: {
    route: [Params.lang, Path.brand, Params.slug_brand, Params.slug_model],
  },
  [Page.modelMarketplace]: {
    route: [Params.lang, Path.brand, Params.slug_brand, Params.slug_model, Path.marketplace],
  },
  [Page.magazineOverview]: {
    route: [Params.lang, Path.magazine],
  },
  [Page.magazineArticle]: {
    route: [Params.lang, Path.magazine, Params.slug_articleId],
  },
  [Page.promotionOverview]: {
    route: [Params.lang, Path.promotions],
  },
  [Page.promotion]: {
    route: [Params.lang, Path.promotion, Params.slug_promotionId],
  },
  [Page.postLogin]: {
    route: [Path.postLogin],
    preventAsPostLoginUrl: true,
  },
  [Page.savedSearchMail]: {
    route: [Path.savedSearches],
    preventAsPostLoginUrl: true,
  },
  [Page.staticPreview]: {
    route: [Params.lang, Path.pagePreview, Params.pageId],
  },
  [Page.staticGeneral]: {
    route: [Params.lang, Path.general],
    wildcard: true,
  },
  [Page.staticYourVehicle]: {
    route: [Params.lang, Path.yourVehicle],
    wildcard: true,
  },
  [Page.staticBrand]: {
    route: [Params.lang, Path.brand, Params.slug_brand, Path.general],
    wildcard: true,
  },
  [Page.staticModel]: {
    route: [Params.lang, Path.brand, Params.slug_brand, Params.slug_model, Path.general],
    wildcard: true,
  },
  [Page.ffuTestDrive]: {
    route: [Params.lang, Path.ffuTestDrive, Params.slug_vehicleId],
    wildcard: true,
  },
  [Page.testDriveBrand]: {
    route: [Params.lang, Path.testDriveBrand, Params.slug_brand],
    wildcard: true,
  },
  [Page.testDriveModel]: {
    route: [Params.lang, Path.testDriveModel, Params.slug_brand, Params.slug_model],
    wildcard: true,
  },
  [Page.leasingIntro]: {
    route: [Params.lang, Path.leasingIntro, Params.slug_vehicleId],
    wildcard: true,
  },
  [Page.leasingApplication]: {
    route: [Params.lang, Path.leasingApplication, Params.slug_vehicleId],
    wildcard: true,
  },
  [Page.leasingRequest]: {
    route: [Params.lang, Path.leasingRequest, Params.slug_vehicleId],
    wildcard: true,
  },
  [Page.vehiclePurchase]: {
    route: [Params.lang, Path.vehiclePurchase, Params.slug_vehicleId],
    wildcard: true,
  },
  [Page.vehiclePurchaseConclude]: {
    route: [Params.lang, Path.vehiclePurchaseConclude, Params.slug_vehicleId],
  },
  [Page.ffuGeneralInquiry]: {
    route: [Params.lang, Path.ffuGeneralInquiry, Params.slug_dealerId],
  },
  [Page.newVehicleInquiry]: {
    route: [Params.lang, Path.newVehicleInquiry, Params.slug_brand, Params.slug_model],
    wildcard: true,
  },
  [Page.vehicleEvaluationIntro]: {
    route: [Params.lang, Path.vehicleEvaluationIntro],
  },
  [Page.vehicleEstimation]: {
    route: [Params.lang, Path.vehicleEstimation],
    wildcard: true,
  },
  [Page.ffuVehicleEvaluation]: {
    route: [Params.lang, Path.ffuVehicleEvaluation],
    wildcard: true,
  },
  [Page.checklist]: {
    route: [Params.lang, Path.tour, Path.checklist],
  },
  [Page.competitionForm]: {
    route: [Params.lang, Path.tour, Path.competitionForm],
  },
  [Page.newsletterSubscribe]: {
    route: [Params.lang, Path.newsletter, Path.subscribe],
  },
  [Page.matchmaker]: {
    route: [Params.lang, Path.matchmaker],
    wildcard: true,
  },
};
